/* eslint-disable */
<template>
  <div>
    <div id="bloc_plante" :class=bloc_class style="color:#000000" >
      <div style="display:flex; width:100%;" >
        <div style="width:133px;height:100px;text-align:center;align-self: center;">
          <div style="display:bloc;width:133px;height:100px;">
            <img :src=plante.images[0] style="width:100%; border-radius:10px;" />
          </div>
        </div>
        <div style="width:17%;text-align:center;align-self: center;">
          <b>{{plante.nom}}</b>
          <br/>
          <div style="color:#888888">{{plante.nom_latin}}</div>
        </div>
        <div style="width:17%;text-align:center;align-self: center;">{{plante.date_soumission}}</div>
        <div style="width:17%;text-align:center;align-self: center;">
         <div v-if=plante.est_securisee class="p_securised" ><v-icon size="25"  style="margin-top:-4px" color="#02bfc2">mdi-check-circle-outline</v-icon> Sécurisée </div>
         <div v-else class="p_to_securise" > À sécuriser </div>
        </div>
        <div style="width:17%;text-align:center;align-self: center;">{{plante.date_securisation}}</div>
        <div style="width:15%;text-align:center;align-self: center;display:flex">

            <div style="width:33%; padding-top: 10px;">
              <v-icon size="30"  color="#02bfc2" v-if=plante.localisation >mdi-map-search-outline</v-icon>
              <v-icon size="30" color="#dadada" v-else >mdi-map-search-outline</v-icon>
            </div>

            <div style="width:33%; padding-top: 10px;">
              <v-icon size="30"  color="#02bfc2" v-if=plante.commentaire >mdi-tooltip-text-outline</v-icon>
              <v-icon size="30" color="#dadada" v-else >mdi-tooltip-outline</v-icon>
            </div>

            <div style="text-align:right">
              <v-img v-if="plante.openCom"
                      style="width: 46px"
                      src="/icons/svg/chevron.svg"
                      class="rotate_chevron_up"
                      @click="closeComment()"
              ></v-img>
              <v-img v-else
                      style="width: 46px"
                      src="/icons/svg/chevron.svg"
                      class="rotate_chevron_down"
                      @click="openComment()"
              ></v-img>
            </div>
        </div>
      </div>
      <div v-if="plante.openCom" class="comment_show" >
        <div style="width:100%;display:block">
          <div class="comment_divider"/>
          <div v-if=plante.localisation class="localisation_plante" >
            <v-icon size="30"  color="#02bfc2" >mdi-map-search-outline</v-icon>
            {{ plante.localisation }}
            </div>
          <div>Commentaire</div>
          <div style="width:100%;padding-top:5px;"><textarea placeholder="Ajouter un commentaire ..." v-model="plante.commentaire" class="invisible_input_com" id="commentaire"  @change="UpdateCommentaire()" ></textarea></div>
        </div>
      </div>
      <div v-else class="comment_hide"  >
      </div>  
    </div>
    <div class="plante_divider"/>
  </div>


</template>

<script>
  import eee_backend from "./mixins/eee_backend";

  export default {
    name: 'Detailplante',
    components: {},
    props :['plante'],
    mixins: [eee_backend],
    data() {
      return { bloc_class: "bloc_plante" }
    },
    methods: {
      UpdateCommentaire()
      {
        this.set_commentaire(this.plante.code_chantier,this.plante.code_galerie,this.plante.commentaire);
      },
      openComment()
      {
        this.plante.openCom = true;
        this.bloc_class = "bloc_plante bloc_plante_deployed" 
      },
      closeComment()
      {
        this.plante.openCom = false;
        this.bloc_class = "bloc_plante" 
      }
    }
  }

</script>