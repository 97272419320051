/* eslint-disable */
<template>
  <div>
    <div v-if=plante.pdf_page class="html2pdf__page-break"/>
    <div class=bloc_plante_pdf style="color:#000000" >
      <div style="display:flex; width:100%;" >
        <div style="width:105px;height:75px;align-self: center;">
          <div style="display:bloc;width:100px;height:75px;">
            <img :src=plante.images[0] style="width:100%; border-radius:10px;" />
          </div>
        </div>
        <div style="width:15%;align-self: center;">
          <b>{{plante.nom}}</b>
          <br/>
          <div style="color:#888888">{{plante.nom_latin}}</div>
        </div>        
        <div style="width:10%;align-self: center;">{{plante.date_soumission}}</div>
        <div style="width:10%;align-self: center;">{{plante.date_securisation}}</div>
        <div style="width:25%;align-self: center;display:flex">
            <div>
              {{plante.localisation}}
            </div>  
        </div>
        <div style="width:25%;align-self: center;display:flex">
            <div>
              {{plante.commentaire}}
            </div>  
        </div>        
      </div>
    </div>
    <div class="plante_divider"/>
  </div>

</template>

<script>
  import eee_backend from "./mixins/eee_backend";

  export default {
    name: 'Detailplantepdf',
    components: {},
    props :['plante'],
    mixins: [eee_backend],
    data() {
    },
    methods: {
    }
  }

</script>